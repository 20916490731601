import React from 'react';
import { Alert } from '../../components/Alert'

const NotificationContext = React.createContext()

const NotificationComponent = (props) => {
  return <>
    <Alert
      component={props.component}
      open={props.open}
      severity={props.status}
      message={props.message}
      onClose={props.onClose}
    />;
  </>
}


export const NotificationProvider = (props) => {
  const [alert, setIsAlert] = React.useState({
    open: false,
    component: "temporary",
    message: "",
    status: "",
  });

  const onClose = () => {
    setIsAlert(state => ({ ...state, open: false }))
  }

  return (
    <NotificationContext.Provider value={{ notify: setIsAlert }}>
      <>
        {props.children}
        <NotificationComponent onClose={onClose} {...alert} />
      </>
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  const { notify } = React.useContext(NotificationContext);

  return notify;
}
