import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useStyles } from './style'
import { Typography } from '../Typography'
import { Box } from '../Box'

export const AnnouncementComponent = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [isOpen, setOpen] = React.useState(() => {
    const mustShow = !JSON.parse(localStorage.getItem(props.id));
    const hasAlreadyShowedOneTime = !sessionStorage.getItem(props.id);

    return mustShow ? hasAlreadyShowedOneTime : false
  });
  const classes = useStyles();

  const onClose = () => {
    sessionStorage.setItem(props.id, true)
    setOpen(false);
  }

  const onClickNotShowAnymore = () => {
    localStorage.setItem(props.id, true)
    onClose();
  }


  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="xs"
      scroll="body"
      fullWidth
    >
      <DialogTitle className={classes.title}>Aviso</DialogTitle>

      <DialogContent>
        <Typography >
          {props.message}
        </Typography>

        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between" mt={3} mb={2}
        >
          <Button
            variant="text"
            onClick={onClickNotShowAnymore}
          >
            Não exibir novamente
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            className={classes.submit}
          >
            Entendi
          </Button>
        </Box>


      </DialogContent>
    </Dialog>
  );
}
