import { Link } from "react-router-dom";
import { Link as MaterialLink } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Box, Tooltip, Typography } from "../../../components";
import {
  formatToBRL,
  formatToCNPJ,
  formatToCPF,
  formatToPhone,
} from "brazilian-values";
import { AdditionalInformation } from "../../../layout";
import { captalizeString } from "../../../utils";
import { MenuPage } from "../Menu";

const ChooseStatus = (status) => {
  let { situation, declined_motive } = status;

  if (!situation && declined_motive) situation = "REPROVADO";

  const object = {
    APROVADO: {
      title: "Aprovado",
      color: "secondary",
      tooltip: false,
    },
    REPROVADO: {
      title: "Reprovado",
      color: "error",
      tooltip: true,
    },
    PENDENTE: {
      title: "Pendente",
      color: "primary",
      tooltip: false,
    },
    default: {
      title: "-",
      color: "textSecondary",
      tooltip: false,
    },
  };

  const storeValue = object[situation] || object.default;

  const { title, color, tooltip } = storeValue;

  return (
    <Box display="flex" justifyContent="flex-start">
      {tooltip ? (
        <Tooltip title={declined_motive} placement="top">
          <Typography color={color} fontWeight={700} variant="body2">
            {title}
          </Typography>
        </Tooltip>
      ) : (
        <Typography color={color} fontWeight={700} variant="body2">
          {title}
        </Typography>
      )}
    </Box>
  );
};

export const tableClientColumns = [
  {
    name: "user_id",
    label: "ID",
  },
  {
    name: "corporate_name",
    label: "Estabalecimento comercial",
    options: {
      customBodyRender: (name) => {
        if (name) {
          return (
            <Typography variant="body2">{captalizeString(name)}</Typography>
          );
        }

        return <Typography variant="subtitle2">{name || "-"}</Typography>;
      },
    },
  },
  {
    name: "infoOriginator",
    label: "Nome",
    options: {
      customBodyRender: (value) => (
        <AdditionalInformation name={value.name} cnpj={value.cnpj} />
      ),
    },
  },
  {
    name: "date",
    label: "Data cadastro",
  },
  {
    name: "document_number",
    label: "CNPJ/CPF",
    options: {
      customBodyRender: (value) => {
        if (value === "" || value === null) return "-";
        return value.length === 11 ? formatToCPF(value) : formatToCNPJ(value);
      },
    },
  },
  {
    name: "operation_value",
    label: "Valor da Operação",
    options: {
      customBodyRender: (value) => (value ? formatToBRL(value) : "-"),
    },
  },
  {
    name: "phone",
    label: "Telefone",
    options: {
      customBodyRender: (value) => (value ? formatToPhone(value) : "-"),
    },
  },
  {
    name: "status",
    label: "Status",
    options: {
      customBodyRender: (value) => ChooseStatus(value),
    },
  },
  {
    name: "viewDetails",
    label: " ",
    options: {
      sort: false,
      viewColumns: false,

      customBodyRender: (value) => {
        const handleDetails = (event) => {
          const name = event.target.getAttribute("data-name");
          sessionStorage.setItem("tableName", name);
        };
        
        const approvedProposal = value.status.situation === "APROVADO";
        const showDetails = value.checkpoint === 'DISBURSEMENT_CONFIRMED';

        return (
          <Box display="flex" justifyContent="space-around" width="150px">
            <VisibilityIcon
              fontSize="small"
              color={
                showDetails ? "primary" : "disabled"
              }
            />
            {showDetails ? (
              <Tooltip
                title="Se a proposta for aprovada não será possivel editar os dados"
                placement="top"
              >
                <MaterialLink
                  underline="always"
                  component={Link}
                  to={value.document_number}
                  color="primary"
                  onClick={handleDetails}
                  data-name={value.name}
                >
                  Detalhes
                </MaterialLink>
              </Tooltip>
            ) : (
              <Typography variant="body2" color="textSecondary">
                Detalhes
              </Typography>
            )}
            
            <Box>
              <MenuPage
                approvedProposal={approvedProposal}
                ecid={value.ecid}
                cnpj={value.cnpj}
              />
            </Box>
          </Box>
        );
      },
    },
  },
];
