import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { CustomersDetailsPage } from "./Details";
import { CustomersMainPage } from "./Main";
import { Announcement } from '../../components/Announcement'

export const CustomersPageComponent = (props) => {

  return (
    <>
      <Announcement id="@Alert/Hide/Migration" message="Olá, estamos em processo de migração. As operações antigas estão disponíveis apenas para visualização através do menu operações antigas." />
      <Switch>
        <Redirect from="/customers/details" exact to="/customers" />
        <Route
          exact
          path="/customers"
          render={(p) => <CustomersMainPage {...p}  {...props} />}
        />
        <Route
          path="/customers/details/:id"
          render={(props) => <CustomersDetailsPage {...props} mainRoute="customers" />}
        />
      </Switch>
    </>
  );
};
