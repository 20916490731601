import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    title: {
      color: theme.palette.primary.main,
    },
    submit: {
      margin: theme.spacing(2, 0, 0,),
    },
  };
});
