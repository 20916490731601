import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Pages } from './pages';
import { GlobalStorage } from './context';
import { NotificationProvider } from './contexts/notification/context';

function App() {

  return (
    <BrowserRouter>
      <CssBaseline />
      <GlobalStorage>
        <NotificationProvider>
          <Pages />
        </NotificationProvider>
      </GlobalStorage>
    </BrowserRouter>
  );
}

export default App;
